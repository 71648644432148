import { useEffect } from "react"

export function DisableCtrlShortcuts() {
    const handleKeyDown = (event: KeyboardEvent): void => {
        if (event.ctrlKey && (event.key === "s" || event.key === "p" || event.key === "k" || event.key === "S" || event.key === "P" || event.key === "K")) {
            event.preventDefault()
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown)
        return () => {
            window.removeEventListener("keydown", handleKeyDown)
        }
    }, [])
}

export function DisableDraggable() {
    useEffect(() => {
        const disableDraggable = () => {
            const allImages = document.getElementsByTagName("img")
            const allLinks = document.getElementsByTagName("a")

            for (let i = 0; i < allImages.length; i++) {
                allImages[i].addEventListener("dragstart", function(event) {
                    event.preventDefault()
                })
            }

            for (let i = 0; i < allLinks.length; i++) {
                allLinks[i].addEventListener("dragstart", function(event) {
                    event.preventDefault()
                })
            }
        }

        disableDraggable()
    }, [])

    return null
}
