"use client"

import Lenis from "lenis"
import { usePathname } from "next/navigation"
import {
    ClickScrollPlugin,
    OverlayScrollbars,
    ScrollbarsHidingPlugin,
    SizeObserverPlugin
} from "overlayscrollbars"
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import { useEffect } from "react"

import { getLocalStorage, setLocalStorage } from "@/helpers/storageHelper"
import { clsx } from "@/libs/clsx"
import { DisableCtrlShortcuts, DisableDraggable } from "@/scripts/disables"

OverlayScrollbars.plugin([
    SizeObserverPlugin,
    ClickScrollPlugin,
    ScrollbarsHidingPlugin
])

const needScrollElement = "div[data-overlayscrollbars-contents]"

export function handleProjectsScroll() {
    const scrollElement = document.querySelector(needScrollElement) as HTMLElement
    if (scrollElement) {
        setLocalStorage("scrollPosition", scrollElement.scrollTop)
    }
}

export default function PageWrapper({
    children
}: Readonly <{
    children: React.ReactNode
}>) {

    // Custom scripts zone
    DisableCtrlShortcuts()
    DisableDraggable()
    // End custom scripts zone

    useEffect(() => {
        const scrollElement = document.querySelector(needScrollElement) as HTMLElement
        const lenis = new Lenis({
            wrapper: scrollElement,
            content: scrollElement,
            duration: 0.6,
            easing: (t: number) => Math.min(1, 1.001 - Math.pow(2, -10 * t)) // https://www.desmos.com/calculator/brs54l4xou
        })
        function raf(time: number) {
            lenis.raf(time)
            requestAnimationFrame(raf)
        }
        requestAnimationFrame(raf)
    }, [])

    const pathname = usePathname()

    useEffect(() => {
        if (pathname === "/projects") {
            const observer = new MutationObserver((mutationsList, observer) => {
            // Look through all mutations that just occurred
                for (let mutation of mutationsList) {
                // If the addedNodes property has one or more nodes
                    if (mutation.addedNodes.length) {
                        const scrollElement = document.querySelector(needScrollElement) as HTMLElement
                        if (scrollElement) {
                            scrollElement.scrollTop = getLocalStorage("scrollPosition", 0)
                            observer.disconnect() // Stop observing when we found the element
                            break
                        }
                    }
                }
            })

            // Start observing the document with the configured parameters
            observer.observe(document, { childList: true, subtree: true })

            return () => {
                observer.disconnect() // Clean up on component unmount
            }
        }
    }, [pathname])

    // nwr4p3r nk0ntnt

    return (
        <OverlayScrollbarsComponent
            className={clsx(
                "h-[calc(100%-0.0625rem-0.0006rem)] w-full pl-8 pr-11 pt-8 pb-[calc(2rem+theme(height.footer))]",
                "not-[#not-this]:h1:font-semibold",
                "this:div-article:min-h-full"
            )}
            options={{
                scrollbars: {
                    autoHide: "never",
                    autoHideDelay: 0,
                    clickScroll: true
                }
            }}
            defer
        >
            {children}
        </OverlayScrollbarsComponent>
    )
}
