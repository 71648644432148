"use client"

import { motion } from "framer-motion"

import { clsx } from "@/libs/clsx"

export default function Content() {

    const wrapper = {
        enter: {

        },
        show: {
            transition: {
                staggerChildren: 0.2
            }
        }
    }

    const title = {
        enter: {
            opacity: 0,
            y: "3.75rem"
        },
        show: {
            opacity: 1,
            y: 0
        }
    }

    const content = {
        enter: {
            opacity: 0,
            y: "1.25rem"
        },
        show: {
            opacity: 0.5,
            y: 0
        }
    }

    return (
        <motion.article
            variants={wrapper}
            initial="enter"
            animate="show"
            data-overlayscrollbars-initialize
        >
            <motion.h1
                variants={title}
                className="mb-16 text-8xl"
            >
                Latest
            </motion.h1>
            <motion.p
                variants={content}
                className="text-justify leading-loose"
            >
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deserunt alias nulla rem soluta numquam facere dicta quia, sed, voluptatibus quisquam at aspernatur asperiores. Commodi maiores aliquam excepturi fuga magnam delectus!
                Magnam quod molestias quas necessitatibus reprehenderit nisi voluptatibus harum, consectetur dolorem nobis temporibus possimus omnis voluptatum corporis delectus ut non doloribus sequi? Molestiae natus maiores, ea id quia aperiam harum.
                Illo provident error deserunt totam voluptate ut optio non quam doloribus, vitae facilis rem nam beatae dicta ratione animi pariatur laboriosam nobis voluptates vel quia accusamus tenetur nesciunt. Quis, saepe.
                Ratione fugiat laboriosam reprehenderit culpa, ut aliquam incidunt quam excepturi laborum vero mollitia, veritatis omnis exercitationem asperiores quasi! Quae numquam laudantium libero quibusdam recusandae est qui facere, rerum deserunt asperiores.
                Nihil, necessitatibus delectus? Repellat rerum ipsum molestiae vero dignissimos nesciunt voluptates necessitatibus natus nemo nam. Qui iure officia amet inventore? Consequatur quam ab aut debitis perferendis neque veniam velit inventore.
                At reiciendis neque eius maiores nisi, dicta deleniti placeat non similique iure, perspiciatis quod consectetur. Quos ex cumque dolores deserunt, quisquam, cum autem blanditiis aliquid impedit, nihil error ullam minima!
                Eius nemo doloribus aspernatur aperiam ut unde aliquam, accusantium blanditiis temporibus velit expedita quis recusandae ex dolor! Ea numquam suscipit adipisci impedit quas autem ipsam dolor omnis, nesciunt nemo similique!
                Aspernatur hic temporibus natus dolore neque. Odit eos, perferendis sint suscipit architecto officia voluptatibus commodi cumque, facere tenetur veniam consequuntur vel totam voluptate exercitationem ducimus! Sequi iure illum quasi corporis.
                Dolores quod at in? Accusantium eveniet veritatis exercitationem ullam inventore maxime laudantium optio quo labore, temporibus perspiciatis architecto! Inventore doloremque vero nemo quisquam. Molestiae quam, iusto ut nam obcaecati optio.
                Ratione earum ducimus cum quis neque necessitatibus! Natus culpa dolorem hic in fugit provident? Nam perferendis voluptates reprehenderit molestiae? Maiores tempore optio dolore. Quibusdam exercitationem, consectetur quaerat odit animi aperiam?
                Illo, velit? Reiciendis eum iusto, saepe officia rem at cum sit voluptatem corporis est dolorum quam beatae aperiam maxime voluptas alias fugit amet accusamus commodi, sunt aliquam enim, necessitatibus error!
                Et, debitis! Placeat deserunt quos iste deleniti. Quae cumque sint beatae debitis laboriosam? Quis non, repudiandae repellat nobis consequuntur cupiditate expedita error minima possimus maxime pariatur illo asperiores sapiente dignissimos!
                Sed ullam eveniet, voluptatem illum sequi obcaecati fugiat ipsam, minus tempora porro vero consequatur corrupti dolor molestiae omnis nesciunt delectus rerum, laboriosam expedita debitis recusandae architecto dignissimos voluptate? Commodi, incidunt.
                Atque maxime recusandae facere, consectetur animi amet inventore nesciunt? Officia libero amet labore unde necessitatibus porro repellendus sed magni dolor. Similique officiis dolorem ipsum! Dolorum suscipit sit voluptas a sunt?
                Reiciendis autem voluptatem nihil et deserunt sunt. Ipsa enim expedita similique iusto minus, quidem vel commodi quisquam porro laudantium velit quam, quae repudiandae corrupti accusamus quaerat rerum. Sit, ipsum ex.
                Velit est, nostrum sapiente eos, optio id sint rem, laboriosam architecto autem repellendus similique harum culpa dolorum modi. Inventore minima quasi soluta placeat officiis deserunt, distinctio natus facilis vero quis.
                Repellat nulla dolorem unde perspiciatis doloribus obcaecati voluptatum delectus. Doloremque doloribus aliquam molestiae corrupti deserunt repudiandae minus, voluptates enim, repellat perspiciatis unde eaque laboriosam, neque hic quaerat ipsum. Autem, cumque.
                Dolor voluptatum sit rem recusandae ipsum hic consequuntur. Veritatis culpa itaque non et nulla eaque odio nihil eius id aliquid nemo obcaecati enim modi aliquam, neque nesciunt cumque praesentium consequuntur!
                Veniam explicabo mollitia laborum, minima cupiditate fugit aliquid quo id, nostrum incidunt neque qui accusantium est rem, fugiat exercitationem praesentium velit dicta distinctio! Odit, deserunt aliquid? Assumenda illo tempore eveniet.
                Necessitatibus accusantium dignissimos repellendus rem in nostrum corporis repellat earum quasi, aliquam sed odit facilis deserunt animi quaerat veniam reiciendis suscipit quibusdam ducimus rerum totam fugiat. Ad minima molestias at?
                Harum deleniti debitis animi aliquam consectetur ex maiores iste est provident possimus totam, consequatur ullam molestiae. Nemo optio libero at. Quam cupiditate debitis, molestias earum labore accusantium esse illum omnis?
                Sequi ut non corporis beatae, assumenda nemo vel placeat doloribus cupiditate porro perferendis aut nulla iusto rerum minus consequuntur recusandae aliquid eligendi quibusdam cumque neque nesciunt vitae sapiente obcaecati. Velit!
                Doloribus quae eum illo inventore quisquam doloremque fugiat! Temporibus officia facere repudiandae nobis eius animi nemo non at ab tempora soluta iusto possimus, labore quas aliquam id ipsam, quidem voluptas?
                Ex eligendi architecto nisi, repellat corporis asperiores rem quibusdam velit deserunt placeat natus, odio nemo itaque error nostrum hic aliquid? Voluptate repudiandae animi, perspiciatis veritatis ipsam ipsa laboriosam iste excepturi!
                Laboriosam, autem officia a laudantium totam nesciunt velit fugiat doloremque odio expedita eius qui voluptate ea saepe nostrum aspernatur corrupti vel quibusdam accusantium esse nulla nihil ad tempora ab? Debitis.
                Neque magnam quia eveniet accusantium culpa ipsum tempore iusto cupiditate, sit unde soluta dolorem distinctio, quis dolore aliquid explicabo beatae eum necessitatibus consectetur ea. Aliquam perspiciatis consequatur ex corrupti in.
                Doloremque reiciendis iusto officiis debitis omnis unde suscipit, dignissimos at. Molestias fuga qui quidem consectetur natus necessitatibus veritatis aperiam! Suscipit hic numquam dolore? Tempora quidem eligendi nemo sit? Suscipit, sequi.
                Dolores animi aperiam unde eius beatae ullam dolore, numquam atque? Animi deserunt rem aliquid voluptates temporibus autem nostrum modi quas eius repellat. Corrupti nemo, vel omnis in consectetur temporibus ex.
                Modi excepturi adipisci iusto totam nisi velit voluptas error maiores distinctio ducimus beatae eaque, libero deserunt at expedita a nobis id quisquam dolor nemo doloribus quae harum? Nesciunt, hic atque.
                Incidunt, sed dolores. Accusantium quis vel placeat mollitia voluptates, minima molestiae, totam atque illum repudiandae labore tempore. Expedita omnis, voluptatum voluptatem nobis deserunt cum sequi. Similique eos dolore quidem corrupti?
                Eum dolores molestias, molestiae at quasi deleniti! Quia, facere dignissimos? Ullam laborum dignissimos est, dolor porro explicabo et laudantium delectus qui. Quaerat similique quasi, delectus animi sapiente nemo necessitatibus soluta?
                Nesciunt doloremque itaque, laboriosam tempore est quam soluta libero sequi atque architecto molestiae ducimus tenetur eaque dolorum animi alias cum error voluptas laudantium fugiat unde qui exercitationem dolor amet. Laborum!
                Iure aut fuga omnis natus nam accusamus, a nemo eum maiores, obcaecati deleniti reiciendis cupiditate, molestiae iusto laudantium architecto ipsum. Voluptatibus alias et saepe delectus a. Magnam enim magni minus.
                Explicabo nulla provident sapiente quisquam vero consequatur et! Fuga accusamus similique ipsa, exercitationem quibusdam velit necessitatibus, ea cumque sed, aliquid officia! Fugit praesentium officia adipisci corporis, dicta possimus maiores distinctio.
                Magni mollitia voluptatem, recusandae ad fugiat vel vitae cumque hic consequatur? Dolor odit ratione amet. Consectetur, deleniti? Voluptatibus reprehenderit repellat autem voluptate error tempora atque, nam, corporis tempore enim odio?
                Inventore voluptate ab magni debitis. Tempore, eum? Saepe, facere. Voluptates, accusamus. Minus dignissimos aut maiores enim perspiciatis at totam animi sunt sint? Neque delectus aspernatur libero beatae rerum et distinctio.
                Natus facere dolor ad expedita eaque dolore illo quaerat labore consectetur nisi ab velit autem ipsa rerum laboriosam modi, placeat voluptates. Dicta beatae magnam dolores sapiente saepe accusamus expedita perferendis?
                Magnam quidem qui expedita sed consectetur, ex id totam tenetur! Pariatur natus unde quia. Cupiditate, commodi. Ratione odio dolore, quis vero sit, reiciendis eos aspernatur earum numquam veniam consequuntur est?
                Illum omnis quod expedita nulla facilis aut reiciendis, sint quo nesciunt quisquam cumque blanditiis quidem doloremque, error dolorem sed impedit, iste odio aperiam at? Velit recusandae odio nesciunt mollitia ullam.
                Quas provident perferendis sapiente doloribus debitis laborum ex fugiat vitae corrupti eum. Hic magnam omnis reiciendis, provident deserunt nostrum vitae obcaecati dicta, ad amet exercitationem dolorem corporis. Iure, nesciunt non.
                Possimus adipisci, perferendis labore culpa consectetur quis quaerat aut id sapiente porro rerum dolore quo dolorum. Blanditiis excepturi magnam ad, a porro earum in quibusdam rerum corrupti iure recusandae dolores.
                Dolores, optio! Distinctio deleniti voluptatem at quia veniam quis ipsum dignissimos tempora, molestiae doloribus quidem officia mollitia! Possimus illo praesentium odit voluptates officiis perferendis. Nostrum cupiditate accusamus provident eos beatae.
                Perferendis molestiae cupiditate id, magnam possimus sed! Nam facere hic quaerat perspiciatis a vero, veritatis pariatur minus aliquam ducimus autem, omnis quo, officia exercitationem ipsa quibusdam. Quam enim hic nihil?
                Eligendi fugit totam quidem maxime et earum labore delectus ipsa laboriosam autem animi vel quisquam quasi libero repudiandae dolor quod, voluptatum natus sapiente omnis reprehenderit sed exercitationem eum dolore. Quos.
                Neque id natus ipsam ea. Id, molestiae sint? Dignissimos, quasi aspernatur? Consectetur nihil temporibus obcaecati doloribus eaque eum possimus ea reiciendis laudantium illum. Fuga nihil, eligendi commodi vero neque mollitia.
                Expedita debitis sed, consectetur numquam suscipit hic enim, nobis quasi placeat error magni! Architecto officiis molestias facere tempora sunt, necessitatibus adipisci praesentium facilis, repellat illum recusandae. Dolorem modi impedit facere!
                Fugiat distinctio recusandae sequi maiores vero rem soluta totam corrupti aut at repellat natus officia, necessitatibus ab culpa ex id ipsum. A doloribus non optio perferendis aliquam nostrum numquam voluptas?
                Dignissimos exercitationem accusamus libero doloribus tenetur ipsum aspernatur facilis vel amet quam perferendis perspiciatis numquam sint nostrum expedita quis veniam dolor voluptatibus magnam vitae iusto ullam cupiditate, et hic. Commodi?
                Nihil saepe itaque harum error ducimus odio recusandae asperiores suscipit, similique ex in sunt at molestiae corrupti minima porro sit veniam fugit tempore possimus? Quo accusamus facere atque porro aliquid.
                Maxime in porro veritatis similique quam! Dolore quos nulla deleniti obcaecati accusantium unde, saepe ducimus aut, rem libero molestias fugit quasi laudantium dolorum error, ut illum. Fugiat suscipit necessitatibus dolorum.
                Dolorem facere quod officiis dolores sunt quia cum debitis accusamus sint repellat ipsum cupiditate asperiores eius eos eaque veniam reiciendis, enim consectetur dignissimos doloremque nam. Quod porro nulla hic distinctio.
                Porro id ab est eos incidunt expedita voluptatum aliquid fuga perspiciatis facere voluptas cum qui ipsa hic perferendis non autem molestiae, obcaecati ea maiores dignissimos ipsam assumenda illo. Earum, suscipit.
                Corporis asperiores incidunt obcaecati ab at explicabo dolor. Ipsam ab perspiciatis sit fugiat culpa labore deleniti tempora facere corrupti, reprehenderit asperiores commodi, qui dicta consectetur minima distinctio sapiente minus maiores.
                Cum, assumenda molestiae totam tempore qui officia ad facilis explicabo dolore voluptates adipisci repellendus earum odit distinctio nam iste quae incidunt et omnis iusto ex! Eaque perferendis ratione placeat necessitatibus!
                Eius voluptate, architecto eaque quibusdam commodi quas. Tenetur aperiam distinctio iure, quos necessitatibus molestiae accusantium qui vitae velit repellendus debitis porro, et reiciendis consectetur quidem sequi dignissimos odit, animi libero.
                Blanditiis quibusdam alias illo autem sequi asperiores eligendi corporis quos nihil necessitatibus inventore tempore sapiente distinctio, incidunt minus earum. Odit inventore eveniet vero labore. Debitis atque neque dolorum eaque architecto!
                Obcaecati omnis aspernatur ut distinctio at veniam, delectus nulla officiis, fuga tenetur laborum facere voluptas vel est exercitationem natus qui quis. Ut dolorum asperiores mollitia, eaque minus nisi numquam eveniet.
                Obcaecati laudantium qui excepturi cumque eum eveniet fugiat, sed provident, mollitia assumenda ut quisquam voluptatibus. Assumenda autem vero commodi magnam harum repellat, laudantium natus delectus, aliquam culpa repellendus velit laborum?
                Ut quos nihil dolor repellat labore commodi voluptatibus unde aperiam, eaque aspernatur quae quod inventore quasi sapiente similique officia ea! Ipsum molestiae quis enim! Minima doloribus aliquid quasi! Harum, nostrum!
                Aspernatur quas neque eligendi doloribus cum sunt eos aliquam enim ratione error, culpa libero delectus ullam suscipit voluptas, facere eaque quia non blanditiis beatae, sapiente nisi dolorum! Expedita, tempora ea!
                Repudiandae quaerat ratione dolores sint veniam blanditiis tempore voluptates provident possimus! Odio quaerat libero deleniti sapiente, nostrum aliquam cupiditate placeat, ipsa ratione, distinctio quis quod! Quos, cumque eius. Porro, nam?
                Expedita rerum natus nobis quam illo odio at perferendis quasi sed, error qui tempore, saepe nostrum nemo dolorem dignissimos ratione exercitationem accusantium voluptas et. In culpa nobis sequi ipsum reiciendis.
                Voluptatum sint debitis obcaecati odio ipsam necessitatibus ratione praesentium error in? Dicta eos, aut repudiandae quibusdam exercitationem praesentium molestiae ducimus inventore consequatur earum voluptate similique commodi vitae optio mollitia aliquam.
                Quibusdam quam a animi numquam sed aut assumenda, ea repudiandae dolorum fugit voluptatum qui adipisci pariatur commodi quo expedita modi iste, ut totam hic odit natus quod beatae! Soluta, aliquam.
                Natus ratione est iste! Reiciendis quibusdam velit quia esse ea dolor tempora assumenda incidunt dignissimos nemo nesciunt consequatur similique id ipsam rerum, error earum, repellendus ab debitis, veniam rem expedita.
                Magnam inventore provident quibusdam quos dolore necessitatibus excepturi, tenetur accusantium nesciunt. Atque iure quaerat minus quis culpa aut totam magnam eaque? Numquam tempora quo ipsam exercitationem, fugit non quas maxime?
                Incidunt totam explicabo laudantium minus voluptas quasi quos. Accusamus, minima libero ipsum nulla eius velit, vero non distinctio cum quidem placeat? Earum architecto perspiciatis magnam, beatae tempore fugit soluta nihil.
                Deserunt dicta modi quidem ex eum ad excepturi provident ratione? Nostrum, neque laudantium hic assumenda id doloremque, iure magni praesentium quaerat fugiat repellat ad libero magnam in repudiandae odio nisi!
                Rem non tenetur molestiae maiores sit eum! Vitae laborum delectus deserunt, incidunt consequuntur inventore eius id, ipsam voluptate veniam expedita placeat quibusdam similique enim quia saepe iure repellat velit voluptatibus.
                Voluptate consectetur voluptatem corrupti! Autem fugit facilis blanditiis. Maxime qui, excepturi impedit, debitis est tempore soluta suscipit, ullam id iste ducimus blanditiis sequi consequuntur fugiat commodi repudiandae ab officia aut.
                Qui nihil asperiores blanditiis sunt, iure itaque quasi suscipit ex officiis architecto cumque, magni beatae adipisci laudantium consequatur autem provident excepturi id illum illo dicta aliquam modi? Facilis, hic consectetur.
                Consectetur a saepe temporibus in? Cum, adipisci suscipit. Praesentium, temporibus reprehenderit omnis accusantium minus dolorum, consectetur sed maiores sit reiciendis cupiditate provident saepe veritatis quisquam harum suscipit id. Voluptatum, quo?
                Optio cumque voluptates hic dolore provident? Sint quibusdam voluptatum incidunt. Dignissimos voluptatibus reprehenderit numquam? Odit quos accusamus voluptate delectus consequuntur corrupti laborum, nisi eos enim doloribus quibusdam aliquid officia deleniti.
                Maiores ea quidem ad quisquam nihil magnam voluptate voluptas obcaecati aspernatur, porro deserunt ex qui accusantium illum voluptatibus dolor optio, rem earum. Nihil dolor adipisci sit a sequi beatae dolores!
                Voluptatibus blanditiis veritatis nam, expedita omnis id aperiam placeat error vel quidem et quos facilis? Quisquam et, qui officiis recusandae dignissimos suscipit earum aperiam in eveniet assumenda aspernatur quam esse.
                Molestias dicta architecto aspernatur ratione optio praesentium corrupti quasi odio temporibus, impedit ex inventore, sapiente labore laudantium fugiat vitae natus sed odit asperiores. Libero vero reprehenderit veritatis cumque harum fuga!
                Dolores, commodi eligendi esse numquam tempora consequuntur non quaerat quas ullam deleniti aut aspernatur vel natus aperiam libero, blanditiis soluta, totam ipsa doloremque? Sit alias saepe in sunt. Impedit, omnis!
                Delectus aliquid ipsa quas nobis. Ratione consectetur ipsam reprehenderit aliquam voluptate eligendi consequuntur similique qui totam harum quam, minima sit unde fugit. Quasi nesciunt quibusdam reprehenderit alias nulla commodi eos.
                Culpa ea obcaecati laboriosam mollitia similique soluta, ipsam omnis fuga voluptatum fugiat assumenda accusantium corporis non alias, sed aliquid dignissimos! Aliquid recusandae porro eius quod, quia harum vitae neque ducimus.
                Illum minus consequuntur officia iure inventore nihil incidunt accusantium, itaque alias fuga eum mollitia temporibus et quidem ipsam corporis fugit natus nisi ex perferendis id. Dignissimos eaque illum sunt dolorum.
                Mollitia repudiandae accusamus voluptas consequatur! Mollitia perspiciatis reiciendis eos optio numquam adipisci placeat similique voluptatum necessitatibus quis hic, quas eaque esse officiis aspernatur modi quisquam eligendi vitae nobis fuga amet!
                Ipsam consequatur incidunt sequi magni eveniet quae quisquam modi aperiam earum quasi suscipit non officia ex, nihil nam. Quia eaque iste et harum veritatis ex officia expedita, eos perferendis minima.
                Magnam amet dicta, voluptate veritatis a sint ducimus aspernatur ab veniam molestiae eligendi fugit vero delectus vel excepturi deserunt repellendus quas voluptates aperiam. Distinctio aperiam earum, optio omnis quas error.
                Aliquid soluta nisi quod illo deleniti quam accusamus sequi ipsum reprehenderit perspiciatis molestias dicta, explicabo hic quis fugit officiis assumenda optio. Voluptatem neque ipsam temporibus ipsa assumenda saepe officia commodi.
                Sed aliquid qui minima, beatae ullam quos est doloribus odit officiis iusto labore fuga commodi, error dolore vitae. Magnam dicta tenetur suscipit dolore cum nobis quis quas quisquam, perspiciatis aperiam!
                Soluta similique laboriosam, asperiores quod possimus molestiae inventore voluptates cumque eius! Iusto laudantium eius repudiandae tempora debitis omnis a ullam nostrum veritatis, rem id quo vero eveniet deserunt repellat. Assumenda.
                Ex nam ad omnis debitis iure sint porro earum, non odit vitae laboriosam nihil corrupti quod ullam necessitatibus quae eligendi magnam quibusdam hic est natus commodi vel! Fuga, sit expedita.
                Rerum non, maiores quae aut dignissimos aliquam quasi nihil sed consequatur cum harum cumque optio repellendus culpa nostrum alias architecto error illum iusto, dolorum ducimus itaque a quidem! Quasi, suscipit.
                Minus vero velit fuga rerum voluptatem illo alias necessitatibus dignissimos illum consequuntur doloribus, natus porro sapiente. Veniam libero recusandae officiis temporibus. Beatae officia doloremque praesentium. Nemo quaerat deserunt perferendis enim!
                Magni porro nihil dolore explicabo perferendis unde officia laudantium rem vero odio numquam, in voluptatum quidem voluptatibus sint accusantium odit eaque deleniti nostrum? Molestias architecto odit eveniet, nihil harum iure.
                Repellendus quidem ratione voluptas in perferendis ut rem maxime delectus nostrum expedita nulla corrupti aut, id autem numquam ex cum ab enim eius. Iste voluptates officiis porro eligendi voluptatem veniam?
                Iste minus facere ad neque, officia ut nihil voluptatum modi deserunt voluptatibus, tempora inventore. Provident similique hic veniam ex quasi! Ut amet repellat at quasi cupiditate ea est, blanditiis dolor?
                Minus, saepe! Repellendus quas, reprehenderit possimus eum nobis saepe! Autem est accusamus error non nesciunt ratione consectetur labore iste iure doloremque. Quas modi dolore, quisquam dolorum sint excepturi iusto! Dolorum!
                Itaque natus quaerat provident magni facere doloremque similique mollitia, iusto nobis eos quam, quis labore accusamus, aliquam optio cum? Aperiam, non. Nulla qui odio provident perferendis. Numquam enim labore voluptate!
                Dolor repellat iure accusamus dolore incidunt! Repellendus libero possimus modi ullam atque repudiandae cupiditate quo error incidunt expedita sed fuga vel, voluptatibus eaque suscipit cum, ea sit accusantium provident aliquam!
                Esse placeat cum, ad eius laudantium tenetur consectetur, optio accusantium voluptatibus aperiam ullam porro voluptas magnam nam repellat rem dolor iusto perferendis omnis eos sapiente? Iste possimus amet eius sed.
            </motion.p>
            <div className={clsx(
                "mt-8 h-20 w-full rounded-xl bg-white/20"
            )}></div>
        </motion.article>
    )
}
